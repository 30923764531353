<template>
  <div>
    <el-form size="mini" :model="baseForm" :rules="baseFormRules" ref="baseFormRef" label-width="100px"
             label-position="right" :disabled="disabled">
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="8">
          <el-form-item label="展示位置" prop="location">
            <el-select v-model="baseForm.location" style="width:100%" placeholder="请选择展示位置"  @change="changeLocation">
              <el-option
                v-for="item in locations"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="商品名称" prop="name">
            <el-input style="width: 100%" v-model="baseForm.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="初始销量" prop="originSales">
            <el-input type="number" style="width: 100%" v-model="baseForm.originSales"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="8">
          <el-form-item label="起订量" prop="tag">
            <el-input type="Number" style="width: 100%" v-model="baseForm.minBuy"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="步增量" prop="tag">
            <el-input type="Number" style="width: 100%" v-model="baseForm.plusStep"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="最大购买量" prop="tag">
            <el-input type="Number" style="width: 100%" v-model="baseForm.maxBuy"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="8">
          <el-form-item label="商品标签" prop="tag">
            <el-input style="width: 100%" v-model="baseForm.tag"></el-input>
          </el-form-item>
        </el-col>
        <!--<el-col :span="8">
          <el-form-item label="商品状态" prop="status">
            <el-switch
              v-model="baseForm.status"
              active-text="正常"
              inactive-text="下架">
            </el-switch>
          </el-form-item>
        </el-col>-->
        <!--<el-col :span="8">
          <el-form-item label="参与抽奖" prop="isLottery">
            <el-switch
              v-model="baseForm.isLottery"
              active-text="是"
              inactive-text="否">
            </el-switch>
          </el-form-item>
        </el-col>-->
        <el-col :span="8">
          <el-form-item label="参与兑换" prop="isExchange">
            <el-switch
              v-model="baseForm.isExchange"
              active-text="是"
              inactive-text="否">
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <!--<el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="8">
          <el-form-item label="夺宝金币" prop="coin">
            <el-input type="number" style="width: 100%" v-model="baseForm.coin"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="开奖人数" prop="reached">
            <el-input type="number" style="width: 100%" v-model="baseForm.reached"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="初始人数" prop="origin">
            <el-input type="number" style="width: 100%" v-model="baseForm.origin"></el-input>
          </el-form-item>
        </el-col>
      </el-row>-->
      <el-row :gutter="5" style="margin-bottom: 10px">
        <el-col :span="8">
          <el-form-item label="商品图标" prop="icon">
            <uploader ref="uploader" :file-list="fileList" :limit-count="limitCount" :hide-upload="hideUpload" @remove-success="handleRemove"
                      @upload-success="handleSuccess"></uploader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="商品视频" prop="video">
            <ele-upload-video
              :fileSize="20"
              :responseFn="handleVideoResponse"
              @error="handleVideoUploadError"
              @delete="handleVideoRemove"
              :action="uploadUrl"
              name="video"
              :headers="imgUploadHeaders"
              v-model="videoUrl"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="商品描述" prop="desc">
            <el-input v-model="baseForm.desc" type="textarea" placeholder="商品描述" rows="7"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import Uploader from '../../common/Uploader'
import EleUploadVideo from '../../common/EleUploadVideo'

export default {
  name: 'GoodsBasePane',
  components: {
    Uploader,
    EleUploadVideo
  },
  props: {
    shopId: {
      type: Number,
      default: 0
    },
    categoryId: {
      type: Number,
      default: 0
    },
    hideUpload: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      uploadUrl: window.g.MEDIA_URL + '/upload',
      menuTitle: '添加商品',
      disabled: true,
      baseForm: {
        originSales: 0,
        status: true,
        isLottery: true,
        isExchange: false,
        location: 0,
        icon: '',
        name: '',
        desc: '',
        tag: '',
        coin: 0,
        origin: 0,
        reached: 0,
        supplierId: 0,
        minBuy: 1,
        maxBuy: 0,
        plusStep: 1,
        video: ''
      },
      videoUrl: '',
      baseFormRules: {
        name: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur'
          }
        ],
        icon: [
          {
            required: true,
            message: '请上传商品图标'
          }
        ]
      },
      // 商品类型
      locations: [{
        label: '列表',
        value: 0
      }, {
        label: '特选',
        value: 1
      }, {
        label: '新品',
        value: 2
      }, {
        label: '热门',
        value: 3
      }, {
        label: '推荐',
        value: 4
      }, {
        label: '折扣',
        value: 5
      }, {
        label: '抢购',
        value: 6
      }, {
        label: '首页',
        value: 7
      }, {
        label: '轮播',
        value: 8
      }, {
        label: '新人专享',
        value: 9
      }],
      fileList: [],
      limitCount: 1
    }
  },
  methods: {
    handleRemove (files) {
      this.baseForm.icon = ''
    },
    handleSuccess (files) {
      this.baseForm.icon = files[0].mediaId
    },
    // 隐藏上传操作
    hideUploadPlus () {
      // if (this.$refs.uploader.uploadFiles.length > 0) {
      this.$refs.uploader.hideUploadPlus()
      // }
    },
    validCommit (fn) {
      this.$refs.baseFormRef.validate(fn)
    },
    getItem () {
      return {
        originSales: parseInt(this.baseForm.originSales),
        status: this.baseForm.status ? 1 : 0,
        isLottery: this.baseForm.isLottery ? 1 : 0,
        isExchange: this.baseForm.isExchange ? 1 : 0,
        location: this.baseForm.location,
        icon: this.baseForm.icon,
        name: this.baseForm.name,
        desc: this.baseForm.desc,
        tag: this.baseForm.tag,
        coin: parseInt(this.baseForm.coin),
        reached: parseInt(this.baseForm.reached),
        origin: parseInt(this.baseForm.origin),
        minBuy: parseInt(this.baseForm.minBuy),
        maxBuy: parseInt(this.baseForm.maxBuy),
        plusStep: parseInt(this.baseForm.plusStep),
        video: this.baseForm.video
      }
    },
    reset () {
      this.$refs.baseFormRef.resetFields()
      this.baseForm.video = ''
      this.fileList = []
    },
    changeLocation (val) {
      this.$emit('locationChange', val)
    },
    handleVideoUploadError (error) {
      /* this.$notify.error({
        title: '上传提示',
        message: '测试环境无法上传文件'
      }) */
      this.alertMessage('上传视频失败', 'error')
      // eslint-disable-next-line no-console
      console.log('error', error)
    },
    handleVideoResponse (file) {
      this.baseForm.video = file.data.list[0].mediaId
      this.videoUrl = window.g.MEDIA_DOWNLOAD_URL + file.data.list[0].mediaId
      console.log('upload video response item:', this.videoUrl)
      return this.videoUrl
    },
    handleVideoRemove () {
      this.baseForm.video = ''
      this.videoUrl = ''
      console.log('delete video:', this.videoUrl)
    }
  }
}
</script>

<style>
.el-form-item__label {
  text-align: right;
}

.el-form--label-left .el-form-item__label {
  text-align: right;
}
</style>
