<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>显示参数</span>
      </div>
      <el-form size="mini" :inline="true" label-width="80px">
        <el-form-item :label="attr.name" v-for="attr in attrsList" :key="attr.id">
          <el-select placeholder="请选择" v-model="attr.sv">
            <el-option :label="val" :value="val" v-for="val in attr.attrVals" :key="val">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <span>服务保障</span>
      </div>
      <el-table ref="terms" class="custom-term-table" :data="termList" border stripe tooltip-effect="dark"
                @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55" prop="sv">
        </el-table-column>
        <el-table-column width="200" prop="title" label="条款标题"></el-table-column>
        <el-table-column prop="desc" label="条款详情"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'GoodsShowAttrPane',
  props: {
    attrsList: [],
    termList: []
  },
  data () {
    return {
      multipleSelection: []
    }
  },
  methods: {
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    getShowData () {
      return {
        showAttrs: this.getSelectedShowAttrsData(),
        terms: this.getSelectedTermData()
      }
    },
    getSelectedShowAttrsData () {
      const showAttrs = []
      for (const item of this.attrsList) {
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty('sv')) {
          showAttrs.push({
            id: item.id,
            name: item.name,
            value: item.sv
          })
        }
      }
      return showAttrs
    },
    getSelectedTermData () {
      const terms = []
      for (const item of this.multipleSelection) {
        terms.push({
          id: item.id,
          title: item.title
        })
      }
      return terms
    },
    toggleRowSelection (idx) {
      this.$refs.terms.toggleRowSelection(this.termList[idx], true)
    }
  }
}
</script>

<style scoped>

</style>
