<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>轮播图</span>
      </div>
      <uploader ref="swiper" :file-list="swiperList" :multiple=true></uploader>
    </el-card>
    <el-card class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <span>详情图</span>
      </div>
      <uploader ref="detail" :file-list="detailList" :multiple=true></uploader>
    </el-card>
  </div>
</template>

<script>

import Uploader from '../../common/Uploader'

export default {
  name: 'GoodsGalleryPane',
  components: {
    Uploader
  },
  props: {
    swiperList: {
      type: Array,
      default () {
        return []
      }
    },
    detailList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    getGallery () {
      const swiper = this.getSwiperImages()
      if (swiper.length <= 0) {
        this.alertMessage('没有轮播图，请提交至少一张图片', 'error')
        return {
          swiper: [],
          detail: [],
          valid: false
        }
      }
      const detail = this.getDetailImages()
      if (detail.length <= 0) {
        this.alertMessage('没有详情图，请提交至少一张图片', 'error')
        return {
          swiper: [],
          detail: [],
          valid: false
        }
      }
      return {
        swiper: swiper,
        detail: detail,
        valid: true
      }
    },
    getSwiperImages () {
      const images = []
      for (const item of this.$refs.swiper.uploadFiles) {
        if (item && item.response && item.response.data && item.response.data.list && item.response.data.list.length > 0) {
          const list = item.response.data.list
          for (const image of list) {
            images.push(image.mediaId)
          }
        } else if (item.remote) {
          images.push(item.mediaId)
        }
      }
      return images
    },
    getDetailImages () {
      const images = []
      for (const item of this.$refs.detail.uploadFiles) {
        if (item && item.response && item.response.data && item.response.data.list && item.response.data.list.length > 0) {
          const list = item.response.data.list
          for (const image of list) {
            images.push(image.mediaId)
          }
        } else if (item.remote) {
          images.push(item.mediaId)
        }
      }
      return images
    },
    reset () {
      this.$refs.swiper.uploadFiles = []
      this.$refs.detail.uploadFiles = []
    }
  }
}

</script>

<style scoped>

</style>
