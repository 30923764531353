<template>
  <div>
    <el-button type="primary" size="mini" @click="showAddDialog" :disabled="disableCheck || isEdit">添加规格
    </el-button>
    <el-table :data="tableData" border stripe style="margin-top: 10px;"
              :row-class-name="atableRowClassName"
              @cell-click="atabClick"
    >
      <el-table-column width="30" type="index" label="#"></el-table-column>
      <el-table-column
        prop="name"
        label="规格名称"
        width="150"
      >
        <template slot="header">
          <span>规格名称</span>
          <i class="el-icon-edit"></i>
        </template>
        <template slot-scope="scope">
            <span v-if="scope.row.index === aClickIndex && aClickProperty === 'name'">
              <el-input v-model="scope.row.name" maxlength="300" size="mini" placeholder="请输入规格名称"
                        @blur="ainputBlur(scope.row)" @keyup.enter.native="ainputBlur(scope.row)" />
            </span>
          <span v-else>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="规格值">
        <template slot-scope="scope">
          <!-- 循环渲染tag标签 -->
          <div v-if=disableCheck>
          <el-tag  style="margin-top:5px;" v-for="(item,i) in scope.row.attrVals" :key="i">{{item}}</el-tag>
          </div>
          <div v-else class="inline-box">
          <div v-for="(tag, index) in scope.row.attrVals" :key="index">
            <el-input
              class="input-new-tag"
              v-if="scope.row.editable[index]"
              :ref="'editableInput' + index"
              size="small"
              v-model="scope.row.attrVals[index]"
              placeholder="请输入规格值"
              @keyup.enter.native="handleEditableInputConfirm(tag, index, scope.row)"
              @change="handleEditableInputConfirm(tag, index, scope.row)"
              @blur="handleEditableInputBlur(tag, index, scope.row)"
            ></el-input>
            <el-tag
              v-else
              @click="showEditTagInput(index, scope.row)"
              :closable="!disableCheck"
              :disable-transitions="false"
              @close="handleClose(index, scope.row)"
            >{{ tag }}</el-tag
            >
          </div>
          <!-- 输入的文本框 -->
          <el-input
            class="input-new-tag"
            v-if="scope.row.inputVisible"
            v-model="scope.row.inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm(scope.row)"
            @blur="handleInputConfirm(scope.row)"
          ></el-input>
          <!-- 添加按鈕 -->
          <el-button v-show=!disableCheck
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput(scope.row)"
          >+ 添加属性</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="180" label="操作">
        <template slot-scope="{row, $index}">
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="removeAttribute($index, row)" :disabled="disableCheck || isEdit">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-form label-width="80px">
      <el-form-item size="mini" :label="attr.name" v-for="attr in attrsList" :key="attr.id">
        <el-checkbox-group size="mini" style="width: 100%" v-model="attr.checkedVals" @change="groupCheckedChange" :disabled=disableCheck>
          <el-checkbox border style="width:200px" v-for="attrVal in attr.attrVals" :label="attrVal.val" :key="attrVal.val"
                       :checked="attrVal.checked" @change="checked=>itemCheckedChange(checked, attrVal)">{{ attrVal.val }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <div v-show=!disableCheck style="margin-top: 10px; border: 1px solid #DCDFE6;">
      <el-button style="margin: 10px 10px 0 10px;" type="primary" size="mini" @click="batchFill" :disabled="disableCheck">批量填充
      </el-button>
      <el-form size="mini" :model="batchVal" label-width="100px" style="margin: 10px;"
             label-position="right" :disabled="disableCheck">
        <div style="border: 1px solid #DCDFE6;">
        <el-row style="margin-top: 20px; margin-bottom: 10px; margin-left: 20px;">规格图片</el-row>
      <el-row type="flex" :gutter="5" style="margin-bottom: 10px;" class="flex-warp">
        <el-col :span="6" v-for="(item, index) in firstSpecs" :key="index">
          <el-form-item :label="item.label" prop="label" label-width="200px">
            <!--<el-input type="number" style="width: 100%" v-model="batchVal.stock"></el-input>-->
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="function(res, file){return handleBatchUploadSuccess(res, file, index)}"
              :headers="imgUploadHeaders"
            >
              <img v-if="item.imageUrl" :src="item.imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <!--<uploader :file-list="scope.row.fileList" :limit-count="1" @remove-success="function(fileList){return handleRemove(fileList,scope.$index)}" @upload-success="function(fileList){return handleSuccess(fileList,scope.$index)}"></uploader>-->
          </el-form-item>
        </el-col>
      </el-row>
        </div>
        <div style="border: 1px solid #DCDFE6;margin-top: 10px;">
        <el-row style="margin-top: 20px; margin-bottom: 10px; margin-left: 20px;">价格与库存</el-row>
      <el-row :gutter="5" style="margin-bottom: 10px;margin-right: 10px;">
        <el-col :span="6">
          <el-form-item label="库存" prop="stock">
            <el-input type="number" style="width: 100%" v-model="batchVal.stock"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="成本价" prop="supplyPrice">
            <el-input type="number" style="width: 100%" v-model="batchVal.supplyPrice"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="市场建议价" prop="marketPrice">
            <el-input type="number" style="width: 100%" v-model="batchVal.marketPrice"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="原价" prop="oldPrice">
            <el-input type="number" style="width: 100%" v-model="batchVal.oldPrice"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
          <el-row v-if="isNewBuyerGoods" :gutter="5" style="margin-bottom: 10px;margin-right: 10px;">
            <el-col :span="6">
              <el-form-item label="新人价" prop="newBuyerPrice">
                <el-input type="number" style="width: 100%" v-model="batchVal.newBuyerPrice"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
    </el-form>
    </div>
    <el-table
      border
      :data="skuList"
      style="width: 100%;margin:10px 0 "
      :row-class-name="tableRowClassName"
      @cell-click="tabClick"
      :span-method="objectSpanMethod"
    >

      <el-table-column
        v-for="(item, index) in specs"
        :key="index"
        :prop="item.name"
        :label="item.name"
      >
      </el-table-column>
      <el-table-column
        prop="icon" width="80"
        label="图标">
        <template slot-scope="scope">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="function(res, file){return handleUploadSuccess(res, file, scope.$index)}"
            :headers="imgUploadHeaders"
          >
            <img v-if="scope.row.imageUrl" :src="scope.row.imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <!--<uploader :file-list="scope.row.fileList" :limit-count="1" @remove-success="function(fileList){return handleRemove(fileList,scope.$index)}" @upload-success="function(fileList){return handleSuccess(fileList,scope.$index)}"></uploader>-->
        </template>
      </el-table-column>
      <el-table-column
        prop="stock"
        label="库存"
      >
        <template slot="header">
          <span>库存</span>
          <i class="el-icon-edit"></i>
        </template>
        <template slot-scope="scope">
            <span v-if="scope.row.index === tabClickIndex && tabClickProperty === 'stock'">
              <el-input type="Number" v-model="scope.row.stock" maxlength="300" placeholder="请输入库存" size="mini"
                        @blur="inputBlur" @keyup.enter.native="inputBlur" />
            </span>
          <span v-else>{{ scope.row.stock }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="supplyPrice"
        label="成本价"
      >
        <template slot="header">
          <span>成本价</span>
          <i class="el-icon-edit"></i>
        </template>
        <template slot-scope="scope">
            <span v-if="scope.row.index === tabClickIndex && tabClickProperty === 'supplyPrice'">
              <el-input type="Number" v-model="scope.row.supplyPrice" maxlength="300" placeholder="请输入成本价" size="mini"
                        @blur="inputBlurPrice(scope.$index, scope.row,scope.column)" @keyup.enter.native="inputBlurPrice(scope.$index, scope.row,scope.column)"/>
               <!--<el-input-number :controls="false" v-model="scope.row.price" placeholder="请输入折扣价" size="mini" :precision="2" @blur="inputBlur" @keyup.enter.native="inputBlur"></el-input-number>-->
            </span>
          <span v-else>{{ scope.row.supplyPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="marketPrice"
      >
        <template slot="header">
          <span>市场建议价</span>
          <i class="el-icon-edit"></i>
        </template>
        <template slot-scope="scope">
            <span v-if="scope.row.index === tabClickIndex && tabClickProperty === 'marketPrice'">
              <el-input type="Number" v-model="scope.row.marketPrice" maxlength="300" placeholder="请输入市场建议价" size="mini"
                        @blur="inputBlurPrice(scope.$index, scope.row,scope.column)" @keyup.enter.native="inputBlurPrice(scope.$index, scope.row,scope.column)" />
            </span>
          <span v-else>{{ scope.row.marketPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="oldPrice"
      >
        <template slot="header">
          <span>原价</span>
          <i class="el-icon-edit"></i>
        </template>
        <template slot-scope="scope">
            <span v-if="scope.row.index === tabClickIndex && tabClickProperty === 'oldPrice'">
              <el-input type="Number" v-model="scope.row.oldPrice" maxlength="300" placeholder="请输入原价" size="mini"
                        @blur="inputBlurPrice(scope.$index, scope.row,scope.column)" @keyup.enter.native="inputBlurPrice(scope.$index, scope.row,scope.column)" />
            </span>
          <span v-else>{{ scope.row.oldPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="isNewBuyerGoods"
        prop="newBuyerPrice"
        label="新人价"
      >
        <template slot="header">
          <span>新人价</span>
          <i class="el-icon-edit"></i>
        </template>
        <template slot-scope="scope">
            <span v-if="scope.row.index === tabClickIndex && tabClickProperty === 'newBuyerPrice'">
              <el-input type="Number" v-model="scope.row.newBuyerPrice" maxlength="300" placeholder="请输入新人价" size="mini"
                        @blur="inputBlurPrice(scope.$index, scope.row,scope.column)" @keyup.enter.native="inputBlurPrice(scope.$index, scope.row,scope.column)"/>
              <!--<el-input-number :controls="false" v-model="scope.row.price" placeholder="请输入折扣价" size="mini" :precision="2" @blur="inputBlur" @keyup.enter.native="inputBlur"></el-input-number>-->
            </span>
          <span v-else>{{ scope.row.newBuyerPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="rate" label="利润率">
        <template slot-scope="scope">
          {{ scope.row.rate | formatFloat }}
        </template>
      </el-table-column>
      <el-table-column prop="salesRate" label="销售利润率">
        <template slot-scope="scope">
          {{ scope.row.salesRate | formatFloat }}
        </template>
      </el-table-column>
      <el-table-column prop="price" label="销售价">
        <template slot-scope="scope">
          {{ scope.row.price | formatFloat }}
        </template>
      </el-table-column>
      <!--<el-table-column prop="price" label="新人价">
        <template slot-scope="scope">
          {{ scope.row.newBuyerPrice | formatInt }}
        </template>
      </el-table-column>-->
      <el-table-column v-if="disableCheck" prop="supplyStatus" label="状态">
        <template slot-scope="scope">
          {{ scope.row.supplyStatus | formatSupplyStatus }}
        </template>
      </el-table-column>

      <el-table-column
        label="#序号"
        align="center"
        type="index"
        width="80">
      </el-table-column>
    </el-table>
    <el-dialog
      title="添加规格"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose">
      <el-form :model="addForm" :rules="formRules" ref="addFormRef" label-width="100px">
        <el-form-item label="规格名称" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addAttribute">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Uploader from '../../common/Uploader'
import { CalcGoodsPrice } from '../../../network/goods'

export default {
  name: 'GoodsSpecsAttrPane',
  /* components: {
    Uploader
  }, */
  props: {
    attrsList: {
      type: Array,
      default () {
        return []
      }
    },
    hideUpload: {
      type: Boolean,
      default: false
    },
    disableCheck: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  filters: {
    formatFloat (value) {
      return value.toFixed(2)
    },
    formatInt (value) {
      return value.toFixed(0)
    },
    formatSupplyStatus (value) {
      console.log('format supply status:', value)
      switch (value) {
        case 0:
          return '下架'
        case 1:
          return '正常'
        default:
          return '正常'
      }
    }
  },
  data () {
    return {
      // checked: false,
      skuList: [],
      specs: [],
      isEdit: false,
      tabClickIndex: null, // 点击的单元格
      tabClickProperty: '', // 当前点击的列名,
      aClickIndex: null, // 点击的单元格
      aClickProperty: '', // 当前点击的列名,
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      addDialogVisible: false,
      addForm: { // 添加表单的规则
        name: ''
      },
      formRules: {
        name: [
          { required: true, message: '请输入规格名称', trigger: 'blur' }
        ]
      },
      tableData: [],
      spanArr: [],
      batchVal: {
        supplyPrice: '',
        marketPrice: '',
        stock: '',
        oldPrice: '',
        newBuyerPrice: ''
      },
      firstSpecs: [],
      firstSpecsName: '',
      isNewBuyerGoods: false
    }
  },
  methods: {
    getSpanArr (data) {
      this.spanArr = []
      // data就是我们从后台拿到的数据
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.pos = 0
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i][this.specs[0].name] === data[i - 1][this.specs[0].name]) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.pos = i
          }
        }
      }
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
    },
    tabClick (row, column, cell, event) {
      switch (column.property) {
        case 'stock':
          this.tabClickIndex = row.index
          this.tabClickProperty = column.property
          break
        case 'supplyPrice':
          this.tabClickIndex = row.index
          this.tabClickProperty = column.property
          break
        case 'marketPrice':
          this.tabClickIndex = row.index
          this.tabClickProperty = column.property
          break
        case 'oldPrice':
          this.tabClickIndex = row.index
          this.tabClickProperty = column.property
          break
        case 'newBuyerPrice':
          this.tabClickIndex = row.index
          this.tabClickProperty = column.property
          break
        default:
          this.tabClickIndex = null
          this.tabClickProperty = ''
          break
      }
    },
    atableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
    },
    atabClick (row, column, cell, event) {
      switch (column.property) {
        case 'name':
          this.aClickIndex = row.index
          this.aClickProperty = column.property
          break
      }
    },
    ainputBlur (row) {
      if (!row.name) {
        return
      }
      this.computeSku()
      this.aClickIndex = null
      this.aClickProperty = ''
    },
    // 失去焦点初始化
    inputBlur () {
      this.tabClickIndex = null
      this.tabClickProperty = ''
    },
    inputBlurPrice (index, row, column) {
      this.tabClickIndex = null
      this.tabClickProperty = ''
      if (row.supplyPrice && row.marketPrice) {
        const marketPrice = Math.round(parseFloat(row.marketPrice) * Math.pow(10, 2)) / Math.pow(10, 2)
        const supplyPrice = Math.round(parseFloat(row.supplyPrice) * Math.pow(10, 2)) / Math.pow(10, 2)
        CalcGoodsPrice({ supplyPrice: supplyPrice, marketPrice: marketPrice }).then(res => {
          const result = res.data
          if (res.status !== 200) {
            // return this.alertMessage('获取信息失败2', 'error')
          }
          this.skuList[index].price = result.data.price
          this.skuList[index].rate = result.data.rate
          this.skuList[index].salesRate = (this.skuList[index].price - supplyPrice) * 100 / supplyPrice
        })
      }
    },
    itemCheckedChange (checked, attrVal) {
      // this.checked = !this.checked
      attrVal.checked = checked
      this.changeCheckedSpecs()
    },
    groupCheckedChange (val) {
      // console.log('----curl checked val:', val, ';---------------------list checkeds:', this.attrsList)
      // this.changeCheckedSpecs()
    },
    changeCheckedSpecs () {
      this.specs = []
      for (const item of this.attrsList) {
        if (item.checkedVals.length > 0) {
          this.specs.push({
            name: item.name,
            sortNum: item.sortNum,
            checked: item.checkedVals
          })
        }
      }
      // console.log('this selected specs length:', this.specs.length, ', attrlist length:', this.attrsList.length, ',specs:', this.specs)
      if (this.specs.length === this.attrsList.length) {
        this.computeSkuOld()
      } else {
        this.skuList = []
      }
    },
    computeSkuOld () {
      const specs = []
      for (const item of this.attrsList) {
        const attrSpecs = []
        for (const val of item.checkedVals) {
          attrSpecs.push({ value: val, attrId: item.id, name: item.name })
        }
        if (attrSpecs.length > 0) {
          specs.push(attrSpecs)
        }
      }
      const skuList = this.genSku(specs)
      const skus = []
      for (const sku of skuList) {
        const skuItem = this.genSkuItem(sku)
        skus.push(skuItem)
      }
      this.skuList = skus
    },
    setIsEdit () {
      this.isEdit = true
    },
    computeSku () {
      this.specs = []
      const specs = []
      var allChecked = true
      for (const item of this.tableData) {
        if (item.attrVals.length <= 0) {
          allChecked = false
          continue
        }
        this.specs.push({ name: item.name, checked: item.attrVals })
        const attrSpecs = []
        for (const val of item.attrVals) {
          attrSpecs.push({ value: val, name: item.name })
        }
        if (attrSpecs.length > 0) {
          specs.push(attrSpecs)
        }
      }
      if (!allChecked || this.tableData.length <= 0) {
        this.skuList = []
        return
      }
      var firstSpecs = []
      this.firstSpecsName = this.tableData[0].name
      for (const attr of this.tableData[0].attrVals) {
        const lastItem = this.firstSpecs.find(item => item.label === attr)
        if (lastItem) {
          firstSpecs.push({
            label: lastItem.label,
            imageUrl: lastItem.imageUrl,
            icon: lastItem.icon
          })
        } else {
          firstSpecs.push({
            label: attr,
            imageUrl: '',
            icon: ''
          })
        }
      }
      this.firstSpecs = firstSpecs
      const skuList = this.genSku(specs)
      const skus = []
      for (const sku of skuList) {
        const skuItem = this.genSkuItem(sku)
        skus.push(skuItem)
      }
      this.skuList = skus
      this.getSpanArr(this.skuList)
    },
    genSkuItem (sku) {
      const dataItem = {}
      var key = ''
      for (const item of sku) {
        dataItem[item.name] = item.value
        key += '_' + item.value
      }
      dataItem.key = key
      const lastSku = this.skuList.find(item => item.key === key)
      if (lastSku) {
        dataItem.price = lastSku.price
        dataItem.oldPrice = lastSku.oldPrice
        dataItem.marketPrice = lastSku.marketPrice
        dataItem.supplyPrice = lastSku.supplyPrice
        dataItem.rate = lastSku.rate
        dataItem.salesRate = lastSku.salesRate
        dataItem.stock = lastSku.stock
        dataItem.icon = lastSku.icon
        dataItem.fileList = lastSku.fileList
        dataItem.imageUrl = lastSku.imageUrl
        dataItem.supplyStatus = lastSku.supplyStatus
        dataItem.newBuyerPrice = lastSku.newBuyerPrice
      } else {
        dataItem.price = 0.00
        dataItem.oldPrice = 0.00
        dataItem.marketPrice = 0.00
        dataItem.supplyPrice = 0.00
        dataItem.rate = 0.00
        dataItem.salesRate = 0.00
        dataItem.stock = 0
        dataItem.icon = ''
        dataItem.fileList = []
        dataItem.imageUrl = ''
        dataItem.supplyStatus = 1
        dataItem.newBuyerPrice = 0.00
      }
      return dataItem
    },
    genSku (attrs) {
      return attrs.reduce((x, y) => {
        const arr = []
        x.forEach(x => y.forEach(y => arr.push(x.concat([y]))))
        return arr
      }, [[]])
    },
    handleRemove (fileList, index) {
      this.skuList[index].icon = ''
    },
    handleSuccess (fileList, index) {
      this.skuList[index].icon = fileList[0].mediaId
    },
    getSku () {
      const specsInfo = this.getSkuSpecs()
      if (!specsInfo.valid) {
        return { skuList: [], specs: {}, valid: false }
      }
      const skuListInfo = this.getSkuList()
      if (!skuListInfo.valid) {
        return { skuList: [], specs: {}, valid: false }
      }
      return { specs: specsInfo.specs, skuList: skuListInfo.skuList, valid: true }
    },
    getSkuSpecs () {
      if (this.tableData.length <= 0) {
        this.alertMessage('请设置规格值', 'error')
        return {
          specs: {},
          valid: false
        }
      }
      if (this.specs.length < this.tableData.length) {
        this.alertMessage('请给所有规格设置规格值', 'error')
        return {
          specs: {},
          valid: false
        }
      }
      const specs = {}
      const attrs = []
      // this.specs.sort((a, b) => { return b.sortNum - a.sortNum })
      for (const spec of this.specs) {
        specs[spec.name] = spec.checked
        attrs.push(spec.name)
      }
      return {
        specs: {
          attrs: attrs,
          specs: specs
        },
        valid: true
      }
    },
    getSkuList () {
      const skuList = []
      for (const [idx, sku] of this.skuList.entries()) {
        /* if (sku.icon === '') {
          this.alertMessage('请上传商品规格的图标', 'error')
          return { skuList: [], valid: false }
        } */
        if (sku.marketPrice === '' || sku.supplyPrice === '' || sku.stock === '') {
          this.alertMessage('请将商品规格的原价、折扣价、库存填写完整', 'error')
          return { skuList: [], valid: false }
        }
        const marketPrice = Math.round(parseFloat(sku.marketPrice) * Math.pow(10, 2)) / Math.pow(10, 2)
        const supplyPrice = Math.round(parseFloat(sku.supplyPrice) * Math.pow(10, 2)) / Math.pow(10, 2)
        const oldPrice = Math.round(parseFloat(sku.oldPrice) * Math.pow(10, 2)) / Math.pow(10, 2)
        const stock = parseInt(sku.stock)
        if (marketPrice === 0 || supplyPrice === 0) {
          this.alertMessage('请将第' + (idx + 1) + '行商品的价格设置为非0值', 'error')
          return { skuList: [], valid: false }
        }
        const attrs = {}
        for (const spec of this.specs) {
          attrs[spec.name] = sku[spec.name]
        }
        const skuItem = {
          // price: price,
          oldPrice: oldPrice,
          supplyPrice: supplyPrice,
          marketPrice: marketPrice,
          stock: stock,
          icon: sku.icon,
          attrs: attrs,
          newBuyerPrice: Math.round(parseFloat(sku.newBuyerPrice) * Math.pow(10, 2)) / Math.pow(10, 2)
        }
        skuList.push(skuItem)
      }
      return { skuList: skuList, valid: true }
    },
    resetSku () {
      this.tableData = []
      this.skuList = []
    },
    handleUploadSuccess (res, file, index) {
      this.skuList[index].icon = res.data.list[0].mediaId
      this.skuList[index].imageUrl = window.g.MEDIA_DOWNLOAD_URL + res.data.list[0].mediaId
    },
    handleBatchUploadSuccess (res, file, index) {
      this.firstSpecs[index].icon = res.data.list[0].mediaId
      this.firstSpecs[index].imageUrl = window.g.MEDIA_DOWNLOAD_URL + res.data.list[0].mediaId
    },
    addDialogClose () {
      this.$refs.addFormRef.resetFields()
    },
    addAttribute () {
      this.$refs.addFormRef.validate(valid => {
        if (!valid) return
        const res = this.tableData.find((item) => {
          return item.name === this.addForm.name
        })
        if (res) {
          this.alertMessage('已经存在相同的规格名称', 'info')
          return
        }
        this.tableData.push({
          inputVisible: false,
          inputValue: '',
          name: this.addForm.name,
          attrVals: [],
          editable: []
        })
        this.addDialogVisible = false
        this.computeSku()
      })
    },
    // 点击按钮展示文本输入框
    showInput (row) {
      row.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    // 文本框失去焦点或按下enter都会触发
    handleInputConfirm (row) {
      if (row.inputValue.trim() === '') {
        row.inputValue = ''
        row.inputVisible = false
        return
      }
      if (this.hasSameAttrVal(row)) {
        this.alertMessage('已经存在相同的属性值', 'info')
        row.inputValue = ''
        row.inputVisible = false
        return
      }
      // 输入了内容，进行后续处理
      row.attrVals.push(row.inputValue)
      row.inputValue = ''
      row.inputVisible = false
      this.computeSku()
    },
    hasSameAttrVal (row) {
      // 属性值不能重复,这里也可以用some
      const result = row.attrVals.every(
        (item) => item !== row.inputValue
      )
      return !result
    },
    // 监听tag标签的删除事件
    handleClose (i, row) {
      this.$confirm('确定删除此规格值吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        row.attrVals.splice(i, 1)
        this.computeSku()
      })
    },
    removeAttribute (i, row) {
      this.$confirm('确定删除此规格吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tableData.splice(i, 1)
        this.computeSku()
      })
    },
    showAddDialog () {
      this.addDialogVisible = true
    },
    showEditTagInput (index, row) {
      this.$set(row.editable, index, true)
      this.$nextTick((_) => {
        const editableInput = 'editableInput' + index
        this.$refs[editableInput][0].$refs.input.focus()
      })
    },
    handleEditableInputConfirm (item, index, row) {
      if (item) {
        let exsit = false
        for (const [idx, attr] of row.attrVals.entries()) {
          if (idx !== index && attr === item) {
            exsit = true
          }
        }
        if (exsit) {
          this.$message({ message: '存在相同的属性值', type: 'info' })
          this.$set(row.editable, index, true)
        } else {
          this.$set(row.editable, index, false)
          this.computeSku()
        }
        // this.$set(row.editable, index, false)
      } else {
        this.$message({ message: '请输入属性值', type: 'info' })
        this.$set(row.editable, index, true)
      }
    },
    handleEditableInputBlur (item, index, row) {
      if (item) {
        let exsit = false
        for (const [idx, attr] of row.attrVals.entries()) {
          if (idx !== index && attr === item) {
            exsit = true
          }
        }
        if (exsit) {
          this.$set(row.editable, index, true)
        } else {
          this.$set(row.editable, index, false)
        }
      } else {
        this.$set(row.editable, index, true)
      }
    },
    batchUpdate (updates) {
      for (const sku of this.skuList) {
        if (this.batchVal.stock) {
          sku.stock = this.batchVal.stock
        }
        if (this.batchVal.oldPrice) {
          sku.oldPrice = this.batchVal.oldPrice
        }
        if (this.batchVal.supplyPrice) {
          sku.supplyPrice = this.batchVal.supplyPrice
        }
        if (this.batchVal.marketPrice) {
          sku.marketPrice = this.batchVal.marketPrice
        }
        if (this.batchVal.newBuyerPrice) {
          sku.newBuyerPrice = this.batchVal.newBuyerPrice
        }
        const target = this.firstSpecs.find(item => item.label === sku[this.firstSpecsName])
        if (target && target.icon) {
          sku.icon = target.icon
          sku.imageUrl = target.imageUrl
        }
        if (updates) {
          sku.price = updates.price
          sku.rate = updates.rate
          sku.salesRate = (sku.price - updates.supplyPrice) * 100 / updates.supplyPrice
        }
      }
    },
    batchFill () {
      // console.log('batch fill:', this.batchVal, ', this.firstSpecs:', this.firstSpecs, ', this.firstSpecsName:', this.firstSpecsName, ', this.skuList:', this.skuList)
      if (this.batchVal.marketPrice && this.batchVal.supplyPrice) {
        const marketPrice = Math.round(parseFloat(this.batchVal.marketPrice) * Math.pow(10, 2)) / Math.pow(10, 2)
        const supplyPrice = Math.round(parseFloat(this.batchVal.supplyPrice) * Math.pow(10, 2)) / Math.pow(10, 2)
        CalcGoodsPrice({ supplyPrice: supplyPrice, marketPrice: marketPrice }).then(res => {
          const result = res.data
          if (res.status !== 200) {
            this.batchUpdate(null)
            return
          }
          this.batchUpdate({ price: result.data.price, rate: result.data.rate, supplyPrice: supplyPrice })
          /* this.skuList[index].price = result.data.price
          this.skuList[index].rate = result.data.rate
          this.skuList[index].salesRate = (this.skuList[index].price - supplyPrice) * 100 / supplyPrice */
        })
      } else {
        this.batchUpdate()
      }
    },
    resetBatchVal () {
      this.batchVal = {
        supplyPrice: '',
        marketPrice: '',
        stock: '',
        oldPrice: ''
      }
      for (const item of this.firstSpecs) {
        item.imageUrl = ''
        item.icon = ''
      }
    }
  }
}
</script>

<style>
checkbox {
  margin-right: 30px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.avatar {
  width: 50px;
  height: 50px;
  display: block;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 5px;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.inline-box {
  display: flex;
  flex-wrap:wrap;
}
.inline-box > div {
  margin-right: 5px;
  margin-top: 5px;
}
.flex-warp {
  flex-wrap: wrap;
}
.filter-container {
  margin-top: 10px;
}
</style>
